.rrr {
  /* height: 80%; */
  /* width: 80%; */
  /* overflow: auto; */
  /* display: flex; */
  /* justify-content: center; */
  text-align: center;
  /* width: 100%;
  align-items: center;
  display: inline-block; */
  overflow: auto;


}

table {
  /* overflow:  visible ; */
  /* background-color: rgb(222, 169, 109); */
  margin: auto;
  /* border-radius: 2%; */
  /* overflow: hidden; */
  /* background-color: coral; */
}

table,
th,
td {
  padding: 2px;
  border: 1px solid black;
  border-collapse: collapse;
  font-size: small;

  /* border-radius: 5%; */
}

th,
td {
  padding-left: 8px;
  /* overflow: scroll; */
  max-width: 350px;
  max-height: 350px;
}

th {
  padding: 2px;
  border-collapse: collapse;
  border-top: 4px solid black;
  border-bottom: 3px solid black;
  /* border-radius: 5%; */
}
.bok{
  font-size: smaller;
  padding: 5px;
  background-color: white;
  color: black;
  border: 1px black solid;
}