.add{
    display: flex;
    /* text-align: center; */
    justify-content: center;
    margin: 1%;
    
}
.titelAdd{
    display: block;

}
.pAdd{
    display: block;
    background-color: white;
    border: 2px black solid;
}
.divlod{
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top:6px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  #mySelect{
    padding: 10px;
    width: 95%;
    height: 90%;
    color: black;
    overflow: auto !important;
    background-color: white;
    border-radius: 4px;
  }