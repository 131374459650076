.root{
    text-align: center;
    align-items: center;
    width: 100%;
    /* background-color: aqua; */
    /* background-color: rgb(194, 226, 226); */
}
.root1{
    /* display: flex; */
    text-align: center;
    width: 30%;
    
    /* background-color: beige; */
    margin: auto; 
    margin-top: 2%;
    margin-bottom: 2%;
    border: 1px black solid;
    border-radius: 19px;
}
.label{
    display: inline-block;
}
.possible{
    display: inline-block;
    width: 30%;
    /* background-color: aquamarine; */
}
.serch{
    width: 95%;
    display: inline-block;
    /* display: flex; */
    border: 1px black solid;
    overflow: hidden;
    padding: 0%;
    background-color: rgb(210, 160, 124);
    /* margin-top: auto; */
    /* background-color: rgb(210, 160, 124); */
}
.iipossible{
    position: relative;
    width: 30%;
    height: 20px;
    display: inline-block;
    border-radius: 30%;
    left: 10px;
    text-align: center;
    margin: 0%;
    padding: 10px;
    /* z-index: 1; */
    border: 1px black solid;

}
.llpossible{
    border: 1px black solid;

    /* overflow: hidden; */
    padding: 5%;
    margin: 0%;
    height:20px;
    width: 30%;
    position: relative;
    bottom: auto;
    background-color: rgb(210, 160, 124);
    /* background-color: rgb(134, 82, 45); */

    padding: 10px;
    right: 40px;
    z-index: 2;
    /* border-end-end-radius: 20%; */
    /* border-bottom-left-radius: 20%; */
    border-radius: 20%;
}
.select{
    padding: 10px;
    width: 95%;
    height: 90%;
    color: black;
    overflow: auto !important;
    background-color: white;
    border-radius: 4px;
  }