.data {
    width: 350px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0; /* Remove auto margin to use absolute centering */
    position: absolute;
    transform: translate(-50%, -50%); /* Shift the element back by half its own width and height to center it */
}

@media screen and (max-width: 768px) { /* adjust 768px to your needs */
    .data {
        width: 200px;
    }
}