.root{
    /* text-align: center; */
    align-items: center;
    width: 100%;
}
.main{
    text-align: center;
    width: 30%;
    margin: auto; 
    margin-top: 2%;
    margin-bottom: 2%;
    /* border: 1px black solid; */
    border-radius: 19px;
}
.cardData{
    border: 1px black solid;
    margin: 10px;
    border-radius: 19px;

}
.buy{
    border: 1px black solid;
    margin: 10px;
    border-radius: 19px;
    padding: 7px;
}
.bonus{
    border: 1px black solid;
    margin: 10px;
    border-radius: 19px;
}
.voucher {
    border: 1px solid black;
    border-radius: 19px;
    margin: 10px auto;
    padding: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    display: block;
    cursor: pointer;
}

.voucher-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.voucher-details {
    display: flex;  
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.ok-div {
    border: 1px black solid;
    position: fixed;
    top: 50%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    background-color: white;
    width: 25%; /* default to desktop view */
    border-radius: 20px;
}

@media screen and (max-width: 768px) { /* assuming 768px is your mobile breakpoint */
    .ok-div {
        width: 70%; /* mobile view */
    }
} 