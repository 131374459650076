div.SelectSearch button{
    width: 80%; 
    margin-right: 10%;
    margin-left: 10%;
    color: black;
    background-color: white;
     border: 1px black solid;
}
.s2{
    /* position: absolute; */
    left: 20%;
    display: block;
    /* width: 50%;  */
    height: 100px;
    overflow: auto;
    /* background-color: ; */
}
div.SelectSearch{
    /* display: contents; */
    align-items: center;
    /* text-align: center; */
}