.pop-up-div {
    position: fixed;
    border: 1px solid black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 5px;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    z-index: 2;
}

@media (max-width: 600px) {
    .pop-up-div {
        width: 70%; /* Adjust width to 50% of the screen on small devices */
    }
}

.label {
    width: 100%; /* Ensure the label spans the full width of its container */
    text-align: center; /* Center the text horizontally */
}
