li{
    list-style-type: none;
    height: 30px;
    width: 30px;
    margin: 5px;
    background-color: powderblue;
    display: inline-block;
}
.divlod{
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top:6px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .cvc{
    
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* adjust 200px to your needs */
    justify-content: center;
    /* background-color: chartreuse; */
    width: 100%;
    /* align-items: center;
    text-align: center; */
    /* margin-top: 10px ; */
    padding-top: 10px;
  }
  .search {

    /* background-color: rgb(172, 26, 26); */
    /* display: inline-block; */
    /* width: 20%; */
    /* height: 20%; */
    margin: 5%;
    margin-right: auto;
    text-align: center;
}
.ls{
    /* background-color: aqua;
    display: inline-block; */
    margin-bottom: 1px;
    /* height: min-content; */
}
  .addp{
    /* width: 20%; */
    margin: 5%;
    display: flex;
    flex-wrap: wrap; /* add this line */
    /* background-color: rgb(51, 40, 27); */
    margin-left: auto;
    /* display: inline-block; */
  }

  .data {
    width: 450px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0; /* Remove auto margin to use absolute centering */
    transform: translate(-50%, -50%); /* Shift the element back by half its own width and height to center it */
    font-size: 10px;
    position: 'fixed';
    border: '5px black solid';
    background-color: white;
    border-radius: 5px;
}

.btn-add { 
  margin: 2px;
  width: 200px;
  height: 50px;
}

@media screen and (max-width: 768px) { /* adjust 768px to your needs */
    .data {
        width: 150px;
    }
}