.div{
    /* position: absolute; */
    display: inline-block;
    color: black;
    background-color: rgb(240, 245, 239);
    border: 1px solid rgb(224, 44, 44);
    width: fit-content;
    height: 80%;
    text-align: center;
    right: 5px;
    border-radius: 15px;
    overflow: hidden;
    margin: 3px;
}
.titel{
    padding: 3px;
    padding-left: 9px;
    margin: 0%;
    width: 100%;
    font-size: small;
    background-color: #fd565d;
    /* font-size: 90%; */
    /* border-radius: 25%; */
}
.tite2{
    padding: 10px;
    margin: 0px;
    /* width: 100%; */
    /* background-color: chocolate; */
    /* font-size: 2ch; */
    /* border-radius: 25%; */
}
