.main-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 200px;
    max-height: 90vh; /* set max-height to 90% of viewport height */
    overflow-y: auto; /* make it scrollable */
    z-index: 2;
}

input[type="checkbox"] {
    margin-right: 8px;
}

label {
    font-weight: bold;
}