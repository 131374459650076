.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* height: auto; */
    background-color: #f0f0f0;
  }
  
  h1 {
    margin-bottom: 20px;
  }
  
  form {
    background-color:rgb(245, 185, 161);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 25%;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: right;
  }
  
  input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  button {
    background-color: #5600ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin: 5px;
  }
  
  button:hover {
    background-color: #5600ff;
  }
h1{
  /* position: absolute;
  left: 600%;
  /* position: absolute; */
  /* left: 97%; */
  /* top: 2%; */ 
}
.err{
  color: brown;
  
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}