
.panel{
  position: -webkit-sticky;
  position: sticky;
  top:  43px;;
  /* border-radius: 50px; */
}
.dt{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin: 0%;
  margin-top: 0%;
  margin-left: 0%;
  margin-right: 0%;
  /* margin-bottom: 10px; */
  background-color: white;
  
}
.h1{
  display: inline;
    /* color: aqua; */
    padding: 1%;
    padding-top: 0%;
    /* position: relative;
    top: 3%;
    right: 80%; */
    /* width: max-content; */
}
.loguot{

   display:inline;
   margin-right: auto;
   margin-left: auto;
}
.link{
    margin: 20px;
    width: 150px;
    height: 1 00px;
    background-color: rgb(235, 185, 135);
}
.b{
    align-items: center;
    background-color:  lightsalmon;
    color: rgb(163, 137, 137);
    border: 1px solid black;;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}
   .bot {
    position: -webkit-sticky; 
    position: sticky;
    top: 156px;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: right;
    margin-top: 0px;
    /* margin: 10px; */
    margin-left: 0%;
    margin-right: 0%;
    background-color: white;
  }
  
  .bs {
    
    /* display: contents; */
    /* margin-left: auto;  */
    text-align: center;
  }
  
  
  
  /* .search label {
    margin-right: 10px;
  } */
  
  