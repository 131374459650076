
li{
    overflow: hidden;
    width: min-content;
    height: min-content;
    background-color: white;
   }
   .next{
    padding: 1px;
    width: min-content;
    height: min-content;
    border: 1px black solid;
    background-color: white;
    color: black;
   }
   .indexPage{
    text-align: center;
   }